import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';

ReactDOM.render(
  <Auth0Provider
    domain="dev-tf2lrgrvxsyyhgoa.us.auth0.com"
    clientId="4BJX91URuJka2l6ALU968kzTlvHHP48L"
    redirectUri={window.location.origin + '/callback'}
    audience="https://api.helioshare.com"
    scope="read:dashboard"
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);